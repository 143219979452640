import React from "react"

const Work = () => (
    <div className="work" style={{display: `flex`, flexWrap: `wrap`, justifyContent: `space-between`, alignItems: `center`, marginTop: `40px`}}>
        <a href="/quantum/" style={{height: `300px`, marginBottom: `0%`}}>
            <div className="work-1" style={{height: `300px`}}></div>
        </a>
        <a href="/tim-burton/" style={{height: `300px`, marginBottom: `0%`}}>
            <div className="work-2" style={{height: `300px`}}></div>
        </a>
        <a href="/icons/" style={{height: `300px`, marginBottom: `0%`}}>
            <div className="work-4" style={{height: `300px`}}></div>
        </a>
        <a href="/otis/" style={{height: `300px`, marginBottom: `0%`}}>
            <div className="work-5" style={{height: `300px`}}></div>
        </a>
    </div>
)

export default Work